@tailwind base;
@tailwind components;
@tailwind utilities;

body{ 
    background-color: #f1f1f1;
    scroll-behavior: smooth;
}

.scrollable-left{
    transform: translateX(-25%);
    transition: all ease-in-out 300ms;
}

.scrollable-right{
    transform: translateX(25%);
    transition: all ease-in-out 300ms;
}

.photo{
    width: 200px;
    height: 200px;
    border: dotted #666 2px;
}

.a4{
    transform: scale(0.240) translate(-159.7%, -163.5%);
    max-height: 0px;
}

.card{
    width: 200px;
    border: dotted #666 2px;
}

.card-image{
    max-width: 125px;
}

.certificate-heading {
    font-family: 'Cedarville Cursive', cursive;
font-family: 'Dancing Script', cursive;
    font-size: 230px;
}

.content {
    line-height: 80px;
}

.tb{
    border: 1px solid #555555;
    padding: 5px;
    /* text-align: center; */
} 


.goog-logo-link,.goog-te-gadget,.gskiptranslate,.goog-te-banner-frame,#goog-gt-tt, .goog-te-balloon-frame,div#goog-gt-{
    display: none;
  }
  .skiptranslate {
    display: none !important;
  }
  .goog-te-gadget {
    display: block !important;
  }

  .goog-te-gadget {
    color: transparent;
    font-size:0px;
  }
  
  .goog-text-highlight {
    background: none;
    box-shadow: none;
  }
  
  #google_translate_element {
    background-color: #f3f3f3;;
  }
  
  #google_translate_element select {
    background:#fff;
    margin-left: 20px;
    color:#222;
    border: solid #999 1px;
    border-radius: 5px;
    padding:6px 8px
  }

  .flag {
    background-image: url('./assets/images/tiranga.avif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(15px) brightness(0.8);
    height: 100%;
  }